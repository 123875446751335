import { render, staticRenderFns } from "./BuCommonHeaderMenuPc.vue?vue&type=template&id=601790e6&scoped=true&lang=pug"
var script = {}
import style0 from "./BuCommonHeaderMenuPc.vue?vue&type=style&index=0&id=601790e6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "601790e6",
  null
  
)

export default component.exports